import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import { getAssetUrl } from "../../Utils";
const StyledNavbar = styled(Navbar)`
  & {
    margin: 0 0 0 0;
    height: 5rem;
    padding: 0 2rem;
    transition: 0.3s background-color ease-in, 0.3s transform ease-in,
      0.3s height ease-in, 0.3s opacity ease-in, 0.3s transparency !important;
    background-color: transparent !important;
    opacity: 1;
    transparency: 1;
  }
  & * {
    transition: 0.3s all ease-in !important;
  }
  &:where(.active, .solid) {
    background-color: #f8f9fa !important;
  }
  &.nav-transition {
    transform: translate(0, -6rem);
    opacity: 0.5;
    transparency: 0;
  }
  &.nav-transition .navbar-collapse.show {
    opacity: 0.5;
    transform: translate(0, -10rem);
  }
  &.nav-transition .navbar-collapse {
    opacity: 0.5;
  }
  &.nav-transition .navbar-nav {
    opacity: 0.5;
  }
  & .navbar-toggler {
    background-color: #f8f9fa !important;
  }
  & .navbar-brand {
    width: 300px;
    height: 4rem !important;
    padding: 0;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 111% auto;
    background-image: url(${getAssetUrl("SunWestWhite.webp")});;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: unset !important;
    transform:translateY(-1px);
  }
  &:where(.active, .solid) .navbar-brand {
    background-image:url(${getAssetUrl("SunWest-Logo.webp")});
    transform:translateY(0px);
    background-size: 100% auto;
  }
  & :where(.navbar-collapse, .navbar-nav) {
    background-color: transparent;
    transition: 0.3s all ease-in !important;
  }
  & .phoneIcon {
    width: 12px;
  }
  & .nav-link {
    max-width: 10rem;
    width: 100%;
    background-color: #00aae4;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
    padding: 6px 8px;
    margin: 0.3rem;
    white-space: nowrap;
    color: white !important;
    font-size: 12px;
    line-height: 18px;
  }
  &
    :is(
      .nav-link:hover,
      .nav-link.active,
      .nav-link:focus,
      .nav-link:active,
      .navlink:focus
    ) {
    background: rgba(0, 171, 228, 0.8) 0 0 no-repeat padding-box;
  }
  @media (max-width: 1111px) {
    & .navbar-collapse {
      margin-left: 3rem;
    }
  }
  @media (max-width: 991px) {
    & .navbar-collapse {
      margin: 0 0;
      position: absolute;
      top: 5rem;
      left: 0;
      width: 100%;
      background-color: transparent !important;
      transition: 0.3s all ease-in !important;
    }
    &:where(.active, .solid) .navbar-collapse {
      background-color: #f8f9fa !important;
    }
    & .navbar-collapse .navbar-nav {
      padding-bottom: 0.5rem;
      display: flex;
      flex-flow: column;
      align-items: center;
      transition: 0.3s all ease-in !important;
      background-color: transparent !important;
    }
    & .navbar-collapse.show .navbar-nav {
      background-color: #f8f9fa;
    }
    & .navbar-collapse .nav-link {
      margin-left: calc(1rem + 10px);
    }
    & .ai-workshop {
      display: none !important;
    }
  }
  @media (max-width: 600px) {
    & {
      height: 4rem;
      padding: 0 0.5rem !important;
    }
    & .navbar-brand {
      width: 190px;
      height: auto;
    }
    & .navbar-collapse {
      top: 4rem;
    }
  }
  @media (max-width: 330px) {
    & .navbar-brand {
      width: 150px;
      height: auto;
    }
  }
`;

export default StyledNavbar;
