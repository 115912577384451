/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

/*
 * Footer used across all the pages
 */

import React, {  useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import styles from "./footer.module.css";
import axios from "axios";
import parser from "html-react-parser";
import { getAssetUrl } from "../../Utils";

const FooterWrapper = styled.div`
  @media (min-width: 992px) {
    .container {
      max-width: 100%;
      padding: 0 50px;
    }
    .awardsSecondCol.col-md-5.col-sm-12.col-12 {
      flex: 28%;
      max-width: 28%;
    }
  }

  & {
    background: url(${getAssetUrl("footer-bg.webp")}) no-repeat;
    background-position: 50% top;
    background-size: cover;
  }

  @media (max-width: 991px) {
    & {
      background: url(${getAssetUrl("footer-bg-mobile.webp")}) no-repeat;
      background-position: 50% bottom;
      background-size: 100% 100%;
    }
  }

  @media (max-width: 500px) {
    & {
      background-position: 50% bottom;
      background-size: cover;
    }
  }
`;

const Footer = (props) => {
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);
  const [footerInfoData, setFooterInfoData] = useState();

  useEffect(() => {
    /**
     * @author Pratik Kajrolkar
     * @date 07/17/2023
     * The below function is used to fetch the swmc footer data which
     * is stored in master JSON in S3 bucket.
     * @returns JSON Object of the requested file
     */
    const fetchSWMCFooterInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_SWMC_FOOTER_INFO)
          .then((response) => {
            //console.log(response.data);
            setFooterInfoData(response.data);
            const observer = new IntersectionObserver(([entry]) => {
              if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting);
                observer.disconnect();
              }
            });
            observer.observe(ref.current);
            // Remove the observer as soon as the component is unmounted
            return () => {
              observer.disconnect();
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchSWMCFooterInfo();
  }, []);

  let date = new Date();
  let currentYear = date.getFullYear();
  return (
    <FooterWrapper>
      <div>
        <footer>
          {footerInfoData ? (
            <>
              <Container>
                <Row>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={styles.desctypo}
                  >
                    <div className={styles.logosize}>
                      <a
                        href={footerInfoData.sunWestLogoInfo.url}
                        target="_blank"
                        rel="noreferrer"
                        title={footerInfoData.sunWestLogoInfo.title}
                      >
                        <img
                          src={footerInfoData.sunWestLogoInfo.imgPath}
                          alt={footerInfoData.sunWestLogoInfo.alt}
                          className={styles.footer_logo}
                        />
                      </a>
                    </div>
                    <br />
                    <div className={styles.footer_desc_text}>
                      {parser(footerInfoData.disclaimer)}
                    </div>
                    <div
                      style={{ fontSize: "12px" }}
                      className={styles.footer_desc_text}
                    >
                      {parser(
                        footerInfoData.nyStateWithSunWestMortgageDisclaimer
                      )}
                    </div>
                  </Col>
                  <Col
                    md={{ span: 6, offset: 0 }}
                    lg={6}
                    sm={12}
                    xs={12}
                    className={styles.contactus}
                  >
                    <div className={styles.contact_block}>
                      <h4>{parser(footerInfoData.contactUs.heading)}</h4>

                      <div className={styles.ul_footer + " " + styles.address}>
                        <div
                          className={styles.contactflex}
                          style={{ display: "flex" }}
                        >
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              marginTop: "4px",
                            }}
                            src={footerInfoData.contactUs.address.imgPath}
                            alt={footerInfoData.contactUs.address.alt}
                          />

                          <p>
                            <a
                              style={{ color: "#fff" }}
                              target="_blank"
                              href={
                                footerInfoData.contactUs.address.googleMapsUrl
                              }
                              rel="noreferrer"
                            >
                              {footerInfoData.contactUs.address.fullAddress}
                            </a>
                          </p>
                        </div>
                        <div className={styles.contactOptions}>
                          <div
                            className={styles.contactflex}
                            style={{ display: "flex" }}
                          >
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                marginTop: "4px",
                              }}
                              src={footerInfoData.contactUs.phone.imgPath}
                              alt={footerInfoData.contactUs.phone.alt}
                            />

                            <p>
                              <a
                                href={
                                  "tel:" +
                                  footerInfoData.contactUs.phone
                                    .phoneNumberWithIntlCode
                                }
                                style={{ color: "#fff" }}
                              >
                                {footerInfoData.contactUs.phone.phoneNumber}
                              </a>
                            </p>
                          </div>
                          <div className={styles.email}>
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                marginTop: "4px",
                              }}
                              src={footerInfoData.contactUs.email.imgPath}
                              alt={footerInfoData.contactUs.email.alt}
                            />

                            <p>
                              <a
                                href={
                                  "mailto:" +
                                  footerInfoData.contactUs.email.email
                                }
                                style={{ color: "#fff" }}
                              >
                                {footerInfoData.contactUs.email.email}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row />
              </Container>
              <hr style={{ borderTop: "1px solid #fff", opacity: "unset" }} />
              <Container>
                <Row className="text-center d-flex justify-content-center">
                  <div className={styles.connectwithUs_block}>
                    <h3>{footerInfoData.connectWithUs.heading}</h3>
                  </div>
                </Row>
                <Row className="text-center">
                  <Col>
                    <div className={styles.social_footer}>
                      {footerInfoData.connectWithUs.socialMediaLinks.map(
                        (socialMediaLinksData, i) => (
                          <a
                            key={i}
                            href={socialMediaLinksData.url}
                            target="_blank"
                            rel="noreferrer"
                            title={socialMediaLinksData.title}
                          >
                            <img
                              className={styles.marginImg}
                              style={{
                                margin: "15px 30px 10px 30px",
                              }}
                              src={socialMediaLinksData.imgPath}
                              alt={socialMediaLinksData.alt}
                            />
                          </a>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
              <hr style={{ borderTop: "1px solid #fff", opacity: "0" }} />
              {/* {images-start} */}
              
              <Container>
                <Row className={styles.logoRow}>
                  <Col lg={1} md={3} sm={4} xs={4}>
                    <div as="a">
                      <img
                        src={
                          footerInfoData.companyAwards.mortgageExecutiveTop50
                            .imgPath
                        }
                        className={styles.top50}
                        alt={
                          footerInfoData.companyAwards.mortgageExecutiveTop50
                            .alt
                        }
                        title={
                          footerInfoData.companyAwards.mortgageExecutiveTop50
                            .title
                        }
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={3} md={3} sm={4} xs={4}>
                    <a
                      href={footerInfoData.companyAwards.bbbRating.url}
                      target="_blank"
                      rel="noreferrer"
                      title={footerInfoData.companyAwards.bbbRating.title}
                    >
                      <img
                        src={footerInfoData.companyAwards.bbbRating.imgPath}
                        className={styles.bbb}
                        alt={footerInfoData.companyAwards.bbbRating.alt}
                      />
                    </a>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={4}>
                    <a
                      href={footerInfoData.companyAwards.bcaRating.url}
                      target="_blank"
                      rel="noreferrer"
                      title={footerInfoData.companyAwards.bcaRating.title}
                    >
                      <img
                        src={footerInfoData.companyAwards.bcaRating.imgPath}
                        className={styles.alliance}
                        alt={footerInfoData.companyAwards.bcaRating.alt}
                      />
                    </a>
                  </Col>
                  <Col
                    lg={1}
                    md={4}
                    sm={4}
                    xs={4}
                    className={styles.ehoBirdeye}
                  >
                    <div ref={ref} className={styles.birdeyeContainer}>
                      {isIntersecting ? (
                        <>
                          <a
                            href={
                              footerInfoData.companyAwards.birdEyeReviews.url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <iframe
                              name="bfiframe"
                              title="birdeye"
                              className={styles.reviewIcon}
                              src={parser(
                                footerInfoData.companyAwards.birdEyeReviews
                                  .iframeUrl
                              )}
                              id="bfpublish"
                              scrolling="no"
                              style={{
                                borderWidth: "0px",
                                display: "block",
                                overflow: "hidden",
                                frameborder: "0",
                                height: "75px",
                              }}
                            />
                          </a>
                          <p
                            style={{ textAlign: "center" }}
                            className={styles.birdeye}
                          >
                            Powered by{" "}
                            <a
                              href={
                                footerInfoData.companyAwards.birdEyeReviews
                                  .poweredByUrl
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Birdeye
                            </a>
                          </p>
                        </>
                      ) : (
                        <div
                          style={{
                            width: "165px",
                            height: "113px",
                            display: "flex",
                            alignItems: "ceter",
                            textAlign: "center",
                          }}
                        >
                          Birdeye
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={3}>
                    <img
                      src={
                        footerInfoData.companyAwards.equalHousingOpportunity
                          .imgPath
                      }
                      alt={
                        footerInfoData.companyAwards.equalHousingOpportunity.alt
                      }
                      title={
                        footerInfoData.companyAwards.equalHousingOpportunity
                          .title
                      }
                      className={`${styles.homicon} img-fluid`}
                      width={"100%"}
                      height={"100%"}
                    />
                  </Col>
                  <Col lg={2} md={3} sm={4} xs={4}>
                    <img
                      src={
                        footerInfoData.companyAwards.equalOpportunityEmployer
                          .imgPath
                      }
                      alt={
                        footerInfoData.companyAwards.equalOpportunityEmployer
                          .alt
                      }
                      title={
                        footerInfoData.companyAwards.equalOpportunityEmployer
                          .title
                      }
                      className={styles.eoe}
                      width={"100%"}
                      height={"100%"}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Col  lg={5} md={5} sm={6} xs={12} className="pt-3 d-flex align-items-end justify-content-center">
                
                      <a
                        href="https://www.swmc.com/autismheroes/"
                        target="_blank"
                        rel="noreferrer"
                        
                      >
                        <img
                          className={` ${styles.tacaBanner} img-fluid`}
                          src="https://resources.swmc.com/swmc-images/minified/TACA-LOGO.jpg"
                          title="Autism Heroes"
                          alt="Autism Heroes Logo"
                        />
                      </a>
                  
                  </Col>
                  <Col lg={5} md={5} sm={6} xs={12}>
                    <div
                      className={styles.cellLogoDiv}
                      style={{ display: "contents" }}
                    >
                      <a
                        href={
                          footerInfoData.companyAwards.poweredByCelligence.url
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          className={styles.cellLogoImg}
                          src={
                            footerInfoData.companyAwards.poweredByCelligence
                              .imgPath
                          }
                          style={{
                            maxWidth: "100%",
                            width: "323px",
                            marginTop: "30px",
                            height: "auto",
                          }}
                          title={
                            footerInfoData.companyAwards.poweredByCelligence
                              .title
                          }
                          alt={
                            footerInfoData.companyAwards.poweredByCelligence.alt
                          }
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
              {/* {images-end} */}
              <hr
                style={{
                  borderTop: "1px solid #fff",
                  opacity: "unset",
                  marginBottom: "unset",
                }}
              />
              <Container className={styles.footer_link_banner}>
                <Row className={styles.footcont}>
                  <Col
                    xl={4}
                    sm={12}
                    className={styles.padleft0 + " " + styles.copyrighttxt}
                  >
                    <p
                      className={styles.ul_footer + " " + styles.footer_list}
                      style={{ textAlign: "left" }}
                    >
                      © {currentYear} Sun West Mortgage Company, Inc. All Rights
                      Reserved
                    </p>
                  </Col>
                  <Col
                    xl={8}
                    sm={12}
                    className={
                      styles.padleft0 +
                      " " +
                      styles.padright0 +
                      " " +
                      styles.dislinks
                    }
                  >
                    <ul
                      className={
                        styles.ul_footer +
                        " " +
                        styles.footer_list +
                        " " +
                        styles.linklist
                      }
                    >
                      {footerInfoData.footerLinks.map((footerLinksData, j) => (
                        <li key={j}>
                          <a
                            href={footerLinksData.url}
                            style={{ color: "#fff" }}
                            title={footerLinksData.title}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {footerLinksData.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            ""
          )}
        </footer>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
