import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import FallBack from "./components/Fallback";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";

const Home = lazy(() => import("./layout/Home"));
const ReferralBrokerList = lazy(() => import("./layout/Dashboard"));
const ReferJob = lazy(() => import("./layout/Refer"));

function App() {
  const [download, setDownload] = useState(false);
  ReactGA.initialize("UA-187484893-2");
  ReactGA.pageview(window.location.pathname + window.location.search);

  //Below function check if the user already submitted the form and allow it to  download the spreadsheet.
  const setDownloadState = (downloadForm) => {
    setDownload(downloadForm);
  };
  
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    return () => (window.onbeforeunload = null);
  }, []);
  return (
    <React.Fragment>
      <Header />
      <Routes>
        <Route
          exact
          path="/dashboard"
          element={
            <Suspense fallback={<FallBack />}>
              <ReferralBrokerList />
            </Suspense>
          }
        />
        <Route
          exact
          path="/refer"
          element={
            <Suspense fallback={<FallBack />}>
              <ReferJob />
            </Suspense>
          }
        />
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<FallBack />}>
              <Home download={download} setDownloadState={setDownloadState} />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
