import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import StyledNavbar from "./styles";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import WorkshopButton from "../Workshop-Button";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu";
import PhoneIcon from "../../images/phone_icon.svg";
import { isMobileOrTablet, navScroll } from "../../Utils";

export default function Header() {
  const navbarRef = useRef();
  const prevScrollRef = useRef(window.scrollY);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHome = pathname === "/";

  const handleMenuClick = () => {
    const navClassList = navbarRef.current.classList;
    const scrollPosition = window.scrollY;
    if (scrollPosition === 0) {
      if (!navClassList.contains("active")) {
        navClassList.add("active");
      } else {
        if (navbarRef.current.children[2].classList.contains("show"))
          navClassList.remove("active");
      }
    }
  };

  useEffect(() => {
    function headerScroll() {
      const currentScrollPos = window.scrollY;
      const navbar = navbarRef.current;
      const isSmallDevice = isMobileOrTablet();

      if (currentScrollPos <= 5) {
        if (!navbar.children[2].classList.contains("show") || !isSmallDevice) {
          navbar.classList.remove("active");
        } else {
          navbar.classList.add("active");
        }
      } else {
        if (prevScrollRef.current > currentScrollPos) {
          // Perform your desired actions for scrolling up here
          navbar.classList.remove("nav-transition");
          navbar.classList.add("active");
        } else if (prevScrollRef.current < currentScrollPos) {
          // Perform your desired actions for scrolling down here
          if (isSmallDevice) {
            navbar.classList.remove("active");
            navbar.classList.add("nav-transition");
          } else {
            navbar.classList.remove("nav-transition");
            navbar.classList.add("active");
          }
        }
      }
      prevScrollRef.current = currentScrollPos;
    }

    window.addEventListener("scroll", headerScroll);

    return () => window.removeEventListener("scroll", headerScroll);
  }, []);

  const handleNavLinkClick = (id) => {
    const navbarHeight = navbarRef.current.offsetHeight;
    const targetElement = document.getElementById(id);
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect(); // Get the element's position relative to the viewport
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = rect.top + scrollTop - navbarHeight; // Calculate offsetTop relative to the document
  
      if (isHome) {
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth"
        });
      } else {
        navigate("/");
        setTimeout(() => {
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
          });
        }, 1000);
      }
    } else {
    }
  };
  
  
  

  return (
    <StyledNavbar
      id="navbar"
      collapseOnSelect
      bg="light"
      expand="lg"
      fixed="top"
      ref={navbarRef}
      className={!isHome ? "solid" : ""}
    >
      <div className="d-flex justify-content-end align-items-center position-relative">
        <StyledNavbar.Brand
          as={isHome ? "a" : Link}
          target={isHome ? "_blank" : ""}
          rel="noreferrer"
          className={`h-auto position-relative`}
          href={isHome ? "https://www.swmc.com/" : "/"}
          to={isHome ? "https://www.swmc.com/" : "/"}
        ></StyledNavbar.Brand>
        <UserWayAccessibilityMenu />
      </div>
      <StyledNavbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={handleMenuClick}
      />
      <StyledNavbar.Collapse className="" id="responsive-navbar-nav">
        <Nav className="d-flex justify-content-end w-100">
          <Nav.Link
            as="a"
            href="tel:+18334786937"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              fluid
              className="phoneIcon"
              src={PhoneIcon}
              alt="Phone icon"
            />
            &nbsp; (833) 478-6937
          </Nav.Link>
          <Nav.Link onClick={() => handleNavLinkClick("rateTable")}>
            See Our Rates
          </Nav.Link>
          <Nav.Link onClick={() => handleNavLinkClick("applyNowForm")}>
            Connect With Us
          </Nav.Link>
          <Nav.Link target="_blank" rel="noreferrer" href={process.env.REACT_APP_SWMC_RETAIL_GUIDELINES}>
            View Product Guidelines
          </Nav.Link>
        </Nav>
      </StyledNavbar.Collapse>
      <WorkshopButton showDesktop />
    </StyledNavbar>
  );
}
